<template>
  <div class="gridMainLayout">
    <div class="gridContainer">
      <div class="gridCardWrapper " v-for="(item, index) in continueData" :key="index">
        <div class="continueWatchCard gridCardLayout">
          <div class="gridPosterLayout loading"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import firebase from "firebase/app";
import "firebase/database";
import Utility from "@/mixins/Utility.js";
import FirebaseActions from "@/mixins/FirebaseActions";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      continueData: 8
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "@/components/Templates/Grid.scss";
.gridMainLayout {
 // padding: 2rem;
  .gridPosterLayout{
    background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
    border-radius: 8px !important;
    overflow: hidden;
  }
}
</style>
